.navbar {
    transition: ease-in-out .25s, height .25s;

    &--fixed {
        @apply shadow;
    }

    &-logo {
        img{
            min-width: 100px;
            width: 13dvw;
        }

        @include media-breakpoint-down(lg) {
            margin-right: auto;
            //margin-left: auto;
        }

        @include media-breakpoint-up(lg) {
            margin-right: auto;
        }


    }

    &-hamburger {
        @apply flex lg:hidden;

        &__btn {

            > span {
                @apply bg-green block transition ease-in-out rotate-0;
                width: 30px;
                height: 3px;
    
                + span {
                    @apply mt-2;
                }
            }
    
            &.show {
    
                > span {
        
                    &:nth-child(1) {
                        margin-top: 0;
                        transform: rotate(135deg);
                    }
        
                    &:nth-child(2) {
                        opacity: 0;
                    }
        
                    &:nth-child(3) {
                        width: 30px;
                        margin-top: -14px;
                        transform: rotate(-135deg);
                    }
              }
            }
        }
    }

    &-menu {
        @apply hidden lg:flex;

        &__actions {
            
        }
    }

    &-search {

        @include media-breakpoint-up(lg) {
            @apply ml-8;
        }

        &__btn {

            .close {
                @apply hidden;
    
                .searchbox-open & {
                    @apply block;
                }
            }
    
            .search {
    
                .searchbox-open & {
                    @apply hidden;
                }
            }
        }
    }
}
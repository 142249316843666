.services {
    @apply flex flex-wrap mt-15 -ml-[3px] -mr-[3px];
    &__card {
        @apply relative flex p-[3px] transition-all duration-500 ease-out;
        &:before {
            content: "";
            @apply absolute opacity-0 inset-0 bg-black/60 transition-all duration-500 ease-out;
        }

        &:hover {
            &:before {
                @apply opacity-100;
            }
            .services__card--cnt {
                .insights__card--cnt-meta {
                    @apply items-center h-max;
                }
                h5 {
                    @apply opacity-100;
                }
                .explore-arrw {
                    @apply bg-white;
                    &:after {
                        @apply content-['\e09f'];
                    }
                }
            }
        }
        &--img {
            @apply w-full h-full;
            img {
                @apply w-full h-full object-cover object-center;
            }
        }
        &--cnt {
            @apply absolute w-full h-full inset-0 z-20 p-6 flex flex-col justify-between transition-all duration-500 ease-out;
            &-meta {
                @apply relative flex gap-x-2 h-full;
            }
            h5 {
                @apply flex items-center justify-end gap-2 text-rg lg:text-[18px] uppercase opacity-0 text-white font-light leading-none transition-all duration-500 ease-out;
            }
            .explore-arrw {
                @apply flex justify-center items-center rounded-full overflow-hidden w-9 h-9 bg-[#76c1c6];
                &:after {
                    @apply content-['\e093'] text-[#526eaf] text-2xl;
                    font-family: "Font Awesome 6 Pro";
                }
            }

            &-cat {
                @apply absolute top-0 left-0 text-white text-sm leading-none border-[3px] border-white rounded-full py-1 px-2 w-max h-max;

                &.black {
                    @apply text-black border-black;
                }
            }

        }
    }
}
$primary: #3e70b9;
$primary-invert: lighten($primary, 100%);

$secondary: #898a8d;
$secondary-invert: darken($secondary, 100%);

$light: #fff;
$light-invert: $primary;

$colors: (
    "primary": ($primary, $primary-invert),
    "secondary": ($secondary, $secondary-invert),
    "light": ($light, $light-invert),
);

.btn {
    @apply inline-flex items-center justify-center h-10 px-4 border border-transparent rounded-sm font-bold;
    text-align: center;
    outline: 0;
    user-select: none;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    &-sm {
        @apply py-1 px-2 text-xs;
        height: unset;
    }

    &-pill {
        @apply rounded-full;
    }

    svg {
        @apply h-4;
    }

    @each $name, $pair in $colors {
        $color: nth($pair, 1);
        $color-invert: nth($pair, 2);
        
        &-#{$name} {
            color: $color-invert;
            background-color: $color !important;
            border-color: lighten($color: $color, $amount: 0%);

            svg {
                fill: $color-invert;
            }

            &:hover {
                color: $color-invert;
                background-color: darken($color: $color, $amount: 7.5%) !important;
                border-color: darken($color: $color, $amount: 7.5%);
            }
        }
    }

    @each $name, $pair in $colors {
        $color: nth($pair, 1);
        $color-invert: nth($pair, 2);

        &-outline-#{$name} {
            background-color: transparent;
            border-color: $color;
            color: $color;

            svg {
                fill: $color;
            }

            &:hover {
                color: $color-invert;
                background-color: $color !important;
                border-color: $color;

                svg {
                    fill: white;
                }
            }
        }
    }
}
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {

    html {
        @apply text-rg;
    }

    body {
        @apply leading-normal font-body tracking-wider;
    }

    h1, h2, h3, h4, h5, h6 {
        @apply font-bold leading-tight;
    }

    h1 {
        @apply text-5xl lg:text-4xl;
    }

    h2 {
        @apply text-4xl lg:text-3xl;
    }

    h3 {
        @apply text-3xl lg:text-2xl;
    }

    h4 {
        @apply text-2xl lg:text-1xl;
    }

    h5 {
        @apply text-1xl lg:text-rg;
    }

    h6 {
        @apply text-rg;
    }

    label {
        @apply inline-block mb-1;
    }
}
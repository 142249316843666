.form {

    &-group {
        @apply mb-4;
    }

    &-input, &-textarea, &-select {
        @apply text-blue border-t-0 border-x-0 border-b-blue w-full px-3 py-2 transition ease-in-out focus:border-blueLight focus:ring-blueLight #{!important};

        &::placeholder{
            @apply text-graySemi;
        }
    }

    &-textarea {
        @apply resize-none;
    }

    &.dg-form { 
        label {
            @apply text-blue font-medium;
        }
        .form-check {
            label {
                @apply font-normal;
            }
            input {
                @apply border-blue mr-2;
            }
        }
    }

    //https://css-tricks.com/the-cleanest-trick-for-autogrowing-textareas/
    .grow-wrap {
        display: grid;

        &:after {
            content: attr(data-replicated-value) " ";
            white-space: pre-wrap;
            visibility: hidden;
            padding: 10px 0.5rem;
            grid-area: 1 / 1 / 2 / 2;
        }

        > textarea {
            height: unset !important;
            // overflow-x: hidden;
            // overflow-y: auto;
            overflow: hidden;
            grid-area: 1 / 1 / 2 / 2;
        }
    }
}

.g-recaptcha {
	position: relative;
    //transform: scale(0.65) translate(-50px, -20px);

    // @include media-breakpoint-only(md) {
    //     left: 50%;
    // 	transform: scale(0.65) translate(-50%,0);
    // }
}
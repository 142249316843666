@import url('https://fonts.googleapis.com/css2?family=Hanken+Grotesk:wght@100;200;300;400;500;600;700;800;900&display=swap');

.content-heading {
    h1 {
        @apply text-[35px] leading-tight font-normal uppercase mb-5;
        @media #{$md-layout} {
            @apply text-4xl mb-0;
        }
        @media #{$sm-layout} {
            font-size: 26px;
        }
    }

    h4 {
        @apply text-[18px] font-normal leading-tight;

        @media #{$md-layout} {
            @apply text-lg;
        }
        @media #{$sm-layout} {
            font-size: 14px;
        }
    }

    &.on-white {
        h1 {
            @apply text-blue;
        }
        h4 {
            @apply text-blue;
        }
    }

    &.on-blue {
        h1 {
            @apply text-white;
        }
        h4 {
            @apply text-white;
        }
    }

}
// FOOTER
.f-nav {
    .f-nav-link {
        @apply relative w-max text-blue text-xs md:text-rg lg:text-lg no-underline;

        &:hover {
            @apply text-[#00C5C9] underline;

            i {
                @apply opacity-100;
            }
        }

        i {
            @apply opacity-0 text-[#00C5C9] ml-2 duration-300 ease-linear transition-opacity;
        }
        
    }

    &.in-blue {
        .f-nav-link {
            @apply text-xs md:text-rg lg:text-lg text-white;

            &:hover {
                @apply text-[#00C5C9];

                i {
                    @apply text-[#00C5C9];
                }
            }

            i {
                @apply text-white;
            }
        }
    }
}
.card {

    &-image {

        img {
            @apply w-full h-full object-cover object-center;
        }
    }

    &-content {

        > h5 {
            @apply mb-4;
        }
    }
}

:root {
    // Colors
    --color-primary: #141414;
    --color-secondary: #1F1F25;
    --color-blue: #3E70B9;
    --color-blueLight: #59C3C8;
    --color-gray: #DADADA;
    --color-grayLight: #F7F7F7;
    --color-graySemi: #B7B7B7;
    --color-grayDark: #354856;

    // Font Family
    --font-primary: 'DM Sans', sans-serif;
    --font-secondary: 'Hanken Grotesk', sans-serif;
    --font-heading: 'DM Sans', sans-serif;
    --font-3: "fontawesome", sans-serif;
}

// Layouts Variation
$smlg-device: 'only screen and (max-width: 1199px)';
$extra-device: 'only screen and (min-width: 1600px) and (max-width: 1919px)';
$laptop-device: 'only screen and (min-width: 1200px) and (max-width: 1599px)';
$lg-layout: 'only screen and (min-width: 992px) and (max-width: 1199px)';
$md-layout:'only screen and (min-width: 768px) and (max-width: 991px)';
$sm-layout:'only screen and (max-width: 767px)';
$large-mobile: 'only screen and (max-width: 575px)';
$small-mobile: 'only screen and (max-width: 479px)';

